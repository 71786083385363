import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { de, en } from "vuetify/locale";
import { createVuetify } from "vuetify";

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    locale: {
      locale: "de",
      messages: { en, de },
    },
  });
  app.vueApp.use(vuetify);
});
